import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('full-view',{attrs:{"title":_vm.$t('app.route.changeEmail')},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c(VBtn,{attrs:{"large":"","depressed":"","color":"primary"},on:{"click":_vm.tryResetEmail}},[_vm._v(" "+_vm._s(_vm.$t("general.save"))+" ")])]},proxy:true}])},[_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCard,{staticClass:"ma-5 pa-5",style:(_vm.getViewHeightStyle(76))},[_c('pin-code-buttons',{attrs:{"purpose":_vm.getPurpose}}),_c(VTextField,{attrs:{"label":_vm.$t('user.pinCode'),"rules":_vm.rules.pinCode,"clearable":"","outlined":"","append-icon":"mdi-shield-check","validate-on-blur":""},model:{value:(_vm.model.resetCode),callback:function ($$v) {_vm.$set(_vm.model, "resetCode", $$v)},expression:"model.resetCode"}}),_c(VTextField,{attrs:{"label":_vm.$t('user.newEmail'),"rules":_vm.rules.email,"clearable":"","outlined":"","append-icon":"mdi-lock","validate-on-blur":""},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}}),_c(VTextField,{attrs:{"rules":_vm.rules.confirmEmail,"label":_vm.$t('user.confirmEmail'),"append-icon":"mdi-lock-check","clearable":"","outlined":"","validate-on-blur":""},model:{value:(_vm.model.confirmEmail),callback:function ($$v) {_vm.$set(_vm.model, "confirmEmail", $$v)},expression:"model.confirmEmail"}}),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"large":"","depressed":"","color":"primary"},on:{"click":_vm.tryResetEmail}},[_vm._v(" "+_vm._s(_vm.$t("general.save"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }